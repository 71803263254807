body {
  margin: 0;
  /* font-family: 'Nunito Sans', sans-serif; */
  

}

code {
  /* font-family: 'Nunito Sans', sans-serif; */

}
h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

::-webkit-scrollbar{
  width: 0.4em;
}
::-webkit-scrollbar-track{
  box-shadow: "inset 0 0 6px rgba(0,0,0,0.00)";
  -webkit-box-shadow: "inset 0 0 6px rgba(0,0,0,0.00)";
}
::-webkit-scrollbar-thumb{
  background-color: rgba(97, 97, 97, 0.61);
  border: 1px solid #70809000;
  border-radius: 10px !important;
}
